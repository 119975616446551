.carousel {
  overflow-x: hidden;
  position: relative;
  margin: -1rem;

  .slider {
    column-gap: 20vw;
    display: flex;
    overflow-x: scroll;
    overscroll-behavior-x: contain;
    scroll-behavior: smooth;
    scroll-snap-type: x mandatory;
    scrollbar-width: none;
    width: 100%;
    will-change: transform;

    &.slider_nav_hidden {
      column-gap: 0;
    }

    &::-webkit-scrollbar {
      display: none;
    }

    .slide {
      flex: 0 0 100%;
      padding: 1rem;
      scroll-snap-align: start;
      scroll-snap-stop: always;
    }
  }

  .slide_nav {
    padding: 1rem;
    bottom: 3rem;
    position: absolute;
    right: 0;
  }

  .slide_indicators {
    padding: 1rem;
    display: flex;
  }
}
