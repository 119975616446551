blockquote {
  p {
    &::before,
    &::after {
      content: '“';
      margin: -4px 0 0 -16px;
      position: absolute;

      @media screen and (max-width: 899px) {
        margin: 0 0 0 -12px;
      }
    }

    &::after {
      content: '”';
      margin: 0;
    }
  }
}
