.checkmark__circle {
  stroke-dasharray: 166;
  stroke-dashoffset: 166;
  stroke-width: 3;
  stroke-miterlimit: 10;
  stroke: #333;
  fill: none;
  animation: stroke 1s cubic-bezier(0.65, 0, 0.45, 1) forwards;
}
.checkmark {
  border-radius: 50%;
  display: block;
  stroke-width: 3;
  stroke: #333;
  stroke-miterlimit: 10;
  box-shadow: inset 0px 0px 0px #333;
  animation: scale 0.3s ease-in-out 1.4s both;
}
.checkmark__check {
  transform-origin: 50% 50%;
  stroke-dasharray: 48;
  stroke-dashoffset: 48;
  animation: stroke 0.5s cubic-bezier(0.65, 0, 0.45, 1) 1s forwards;
}
@keyframes stroke {
  100% {
    stroke-dashoffset: 0;
  }
}
@keyframes scale {
  0%,
  100% {
    transform: none;
  }
  50% {
    transform: scale3d(1.1, 1.1, 1);
  }
}
