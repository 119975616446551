.open-menu {
  body {
    height: 100%;
    overflow: hidden;
  }

  .main-wrapper {
    position: relative;
    z-index: 9999999;
  }
}

.side-menu {
  position: fixed;
  transform: translateX(100%);
  transition: all 0.2s ease-out;
  .open-menu & {
    transform: translateX(0%);
  }
}

.top-navigation {
  transition: all 0.4s;

  .side-menu .logo {
    display: block;
  }

  .side-menu .link {
    color: #fff;
  }

  .logo {
    &.white {
      display: block;
    }
    &.dark {
      display: none;
    }
  }

  .link {
    border-bottom: 2px solid transparent;
    color: white;
    padding-block: 10px;
    padding-inline: 5px;
    transition: all 0.2s;

    &:hover {
      border-bottom: 2px solid white;

      &.mobile-link {
        border-color: transparent;
      }
    }
  }

  .button {
    &.white {
      display: inline-flex;
    }
    &.dark {
      display: none;
    }
  }

  .menu-button {
    color: #ffffff;
  }

  [data-header='dark'] & {
    background-color: #002a3a;
  }

  [data-header='light'] & {
    background-color: #f9f7f2;

    .logo {
      &.white {
        display: none;
      }
      &.dark {
        display: block;
      }
    }
    .side-menu {
      .logo {
        &.white {
          display: block;
        }
        &.dark {
          display: none;
        }
      }
    }

    .link {
      color: #002a3a;
      border-bottom-color: transparent;

      &:hover {
        border-bottom-color: #002a3a;
      }
    }

    .mobile-link {
      color: white;
    }

    .button.dark {
      display: inline-flex;
    }
    .button.white {
      display: none;
    }

    .menu-button {
      color: #002a3a;
    }
  }
}
